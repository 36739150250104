'use client';
import { useQuery } from '@tanstack/react-query';
import http from '@framework/utils/http';
import { TProduct } from '@services/mage/catalog-product-types';
import { ProductUtils } from '@services/mage/catalog-product-utils';
import { redirect } from 'next/navigation';
import { cache, useEffect } from 'react';


type TProps = {
    lang: string;
    pathname: string;
}

const getProductUrl = cache((product: TProduct, lang: string) => {      
      const url = ProductUtils.getProductUrl(
        product,
        '',
        '',
        lang,
      );

      return `${url}`;
})

const PageNotFoundRedirect = ({lang, pathname}: TProps) => {  

    const sku = pathname.replace(`/${lang}/`, '')

    // check the path is a product sku
    const {isPending, data} = useQuery({
        queryKey: ['page-not-found', sku],
        queryFn: async () => {
            const res = await http.get(`/products/?sku=${sku.toLowerCase()}`);
            return res.data
        }
    });

    useEffect(() => {

        if (!isPending && data && data.result) {
            const product = data.result as TProduct;
            const productUrl = getProductUrl(product, lang)
            redirect(productUrl)
        }

    }, [isPending, data])

    return null

};

export default PageNotFoundRedirect;

'use client'
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from '@components/ui/carousel/slider';
import Heading from '@components/ui/heading';
import { setUrl } from '@utils/url';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

const breakpoints = {
  '1024': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '768': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '680': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '375': {
    slidesPerView: 4,
    spaceBetween: 4,
  },
  '0': {
    slidesPerView: 1.5,
  },
};

interface TGalleryType {
  title: string;
  image: string;
  alt?: string;
  url: string;
}

interface GalleryCarouselProps {
  lang: string;
  className?: string;
  heading: string;
  paragraph?: React.ReactNode;
  data: TGalleryType[];
  carouselBreakpoints?: any;
}

export const GalleryCarousel = ({
  lang,
  className,
  heading,
  paragraph,
  data,
  carouselBreakpoints,
}: GalleryCarouselProps) => {
  return (
    <div
      className={`max-w-[1440px] px-4 py-6 md:px-6 lg:px-0 relative mx-auto bg-white my-8 sm:my-8 text-center ${className}`}
    >
      <div className="flex flex-wrap items-center justify-center mb-3 md:mb-6">
        <div className="-mt-1.5">
          <Heading variant="jewelCarouselHeader" className="mb-0">
            {heading}
          </Heading>
          {paragraph && (
            <p className="font-outfit text-sm md:text-base font-light text-center py-2">
              {paragraph}
            </p>
          )}
        </div>
      </div>
      <div className="mx-auto w-full">

            <div className='xs:block sm:block md:hidden lg:hidden xl:hidden'>
              <div className="mt-3 grid grid-cols-2 gap-x-2 gap-y-4 lg:gap-y-8 lg:grid-cols-2 xl:gap-x-6">
                {data.map((collection: TGalleryType, idx) => (
                  <div
                    key={`bc-${idx}`}
                    className="group relative cursor-pointer"
                  >
                    <Link href={setUrl(collection.url, lang)}>
                      <div className="aspect-w-1 aspect-h-1 overflow-hidden bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-100">
                        <picture>
                            <source srcSet={collection.image} media='(max-width: 390px)' />
                            <img
                                src={collection.image}
                                alt={collection.alt}
                                width={320}
                                    height={320}                          
                                />
                        </picture>
                      </div>
                      <div className="mt-1 lg:mt-2">
                        <div>
                          <h3 className="font-cardo text-sm lg:text-lg font-normal text-brand-grey-0 text-center">
                            <span aria-hidden="true" className="absolute inset-0" />
                            {collection.title}
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              </div>

              <div className='xs:hidden sm:hidden md:block lg:block xl:block'>
         <Carousel            
          breakpoints={carouselBreakpoints || breakpoints}
          lang={lang}
          prevActivateId="gallery-carousel-button-prev"
          nextActivateId="gallery-carousel-button-next"          
        >
          {data.map((value: TGalleryType, idx) => (
            <SwiperSlide key={`shopbyCategory-key-${idx}`}>
              <Link
                href={setUrl(value.url, lang)}
                className={clsx(
                  'cursor-pointer border-2 border-transparent flex flex-col justify-center',
                )}
              >
                <div className="relative">
                  <div className="overflow-hidden mx-auto relative h-[280px] w-[347px]">
                    <Image
                      className="object-cover h-full"
                      src={value.image}
                      alt={value?.alt || value?.title}
                      quality={100}
                      priority
                      fill
                      sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
                    />
                  </div>
                </div>

                <h3 className="px-2 py-2.5 text-brand-grey-0 font-cardo text-lg font-normal capitalize">
                  {value.title}
                </h3>
              </Link>
            </SwiperSlide>
          ))}
        </Carousel>
        </div>
        
      </div>
    </div>
  );
};

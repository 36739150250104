import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/jewel-store/node_modules/.pnpm/next@14.2.27_@babel+core@7.26.10_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/jewel-store/src/components/404/page-no-found-redirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GalleryCarousel"] */ "/home/jewel-store/src/components/product/feeds/jewel-gallery-feed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactOptions"] */ "/home/jewel-store/src/content/pages/front-main/contact-options.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EvergreenBanner"] */ "/home/jewel-store/src/content/pages/front-main/EvergreenBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Questionaire"] */ "/home/jewel-store/src/content/pages/front-main/questionaire.tsx");
